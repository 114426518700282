.staff-call-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 45px;
}
.custom-outline-button {
  color: #4ec6e5 !important;
  border-color: #4ec6e5 !important;
}
.custom-outline-button:hover {
  background-color: #4ec6e5 !important;
  color: white !important;
}

.ol-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 5px;
}
.ol-wrapper.not-zero {
  margin-top: 20px;
}
.ol-header {
  display: flex;
  font-weight: bolder;
  color: orange;
}
.order-list-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.ol-item-name {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.ol-option-name {
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
  font-size: 14px;
  margin-bottom: 5px;
}
.ol-total-amount {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  margin-top: 8px;
}
.ol-time {
  margin-bottom: 10px;
  text-align: start;
  color: #07746A;
}