.input-phone {
  font-size: 14px;
  margin-bottom: 10px;
}
.wrong-number {
  font-size: 16px;
  margin-bottom: 5px;
  color: red;
}

.phone-num {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border: 1px solid #b2b2b2;
  border-radius: 7px;
  font-size: 16px;
  margin-bottom: 10px;
}
.warn-phone {
  margin-top: 3px;
  font-size: 13px;
  color: red
}