.scrollable-tabs-container {
  display: flex;
  overflow-x: auto;
  align-items: center;
  white-space: nowrap;
  padding: 10px 0px;
  margin: 20px 0px;
  width: 100%;                      /* 반드시 필요한 속성 없으면 top 포지션일때 그룹탭 짤림.*/
  border-bottom: 1px solid #ccc;
  background-color: white;
}

.scrollable-tabs {
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  width: 100%;
  /* overflow-x: auto; */
/*  scrollbar-width: none; /* Firefox */
/*  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

/* .scrollable-tabs::-webkit-scrollbar {
  width: 0;
  height: 0;
} */

.tab-button {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  padding: 8px 15px;
  border: 1px solid #b2b2b2;
  box-shadow: rgba(100, 100, 100, 0.2) 0px 0px 15px 0px;
  border-radius: 15px;
  min-width: 20%;
  background: none;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  color: black;
}

.tab-button:hover, .tab-button:focus {
  background-color: #05b8a0;
  color: white;
}

.scroll-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 24px;
  cursor: pointer;
  outline: none;
  z-index: 1;
  background-color: white
}

.scroll-arrow.left {
  position: absolute;
  padding-left: 10px;
  padding-right: 0px;
  left: 0;
  color: #b2b2b2;
}

.scroll-arrow.right {
  position: absolute;
  padding-left: 0px;
  padding-right: 10px;
  right: 0;
}