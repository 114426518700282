.NotFound {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  gap: 150px;
  justify-content: space-between;
  height: 100%;
  background-color: #05b8a0;
}
.info-wrapper {
  box-sizing: border-box;
  display: flex;
  gap: 15px;
  padding: 0px 20px;
}

.as-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px 20px;
}
.NotFound > div {
  color: white;
  word-break: keep-all;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  line-height: 1.5;
}