.menu-item-list {
  font-family: NanmumB;
  font-size: 16px;
  
}

.option-item-list {
  font-size: 18px;
}

.cart-item-list {
  font-size: 16px;
}

.order-button {
  font-size: 18px;
}

.ol-option-price {
  font-size: 14px;
}

.detail-main-price {
  font-size: 18px;
}