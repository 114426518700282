.CartItem {
  border: 1px solid #eeeeee;
  border-radius: 7px;
  padding: 5px 10px;
  width: 100%;
}

.items {
  margin-top: 20px;
  width: 100% !important;
}
.name-close {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.main-name {
  font-family: Jalnan;
  font-size: 15px;
}
.option-name {
  white-space: pre-wrap;
  margin-left: 10px;
  font-size: 14px;
  color: gray;
}
.price-info {
  margin-top: 10px;
  font-weight: bolder;
  margin-bottom: 5px;
}

.counter-modify {
  border-bottom: 1px solid #eeeeee;
}

.cart-counter-button {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-top: 5px;
  margin-top: 10px;
}