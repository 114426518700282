.MenuItem {
  display: flex;
  width: 100%;
  min-height: 120px;
  justify-content: space-between;
  padding: 10px 2px;
  margin-bottom: 2px;
  border-bottom: 1px solid #eeeeee;
}
.IsBest {
  display: flex;
  width: 100%;
  min-height: 120px;
  justify-content: space-between;
  padding: 10px 2px;
  margin-bottom: 2px;
  border: 1px solid red;

}
.SoldoutItem {
  display: flex;
  width: 100%;
  min-height: 120px;
  max-height: 150px;
  justify-content: space-between;
  padding: 10px 5px;
  border-bottom: 1px solid #eeeeee;
  opacity: 0.3;
}
.soldout {
  position: absolute;
  left: calc(30%);
}

.prod-image {
  display: flex;
  align-items: flex-start;
}

.item-name {
  font-size: 15px;
  font-weight: bolder;
}

.item-desc {
  font-size: 14px;
  color: #b2b2b2;
}

.tab-content {
  padding: 10px 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: white;
  margin: 0px 0px 15px 0px;
  box-shadow: rgba(100, 100, 100, 0.2) 0px 0px 15px 0px;
}
.tab-content > h4 {
  font-size: 18px;
  font-weight: bold;
  /* color: #07746A; */
  color: black;
  margin: 15px 0px;
}