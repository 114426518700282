.IsEssential {
  display: flex;
  align-items: center;
  margin: 0px;
}
.common {
  /* display: inline-block; 넓이를 text의 길이만큼만 커지도록 설정. */
  border: none;
  border-radius: 8px;
  padding:  2px 8px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.essential {
  background-color: #feefd3;
  /* color: #07746A; */
  color: red;
}

.extra {
  background-color: #bbebe5;
  color:  #07746A;
}

