.BottomButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  border: none;
  border-top: 1px solid #eeeeee;
  box-sizing: border-box;
}

.go-button {
  width: 95%;
  height: 60px;
  display: flex;
  background-color: #05b8a0 !important;
  color: white !important;
  border: none !important;
  outline: none !important;
  border-radius: 5px !important;
  font-size: 18px !important;
  font-weight: bold !important;
}
.go-button:active {
  background-color: #07746A !important;
}

.go-button-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.go-button-text {
  font-size: 16px;
}