.fullscreen-modal {
  position: relative;
  box-sizing: border-box;
}
.fullscreen-modal .modal-dialog {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.fullscreen-modal .modal-content {
  width: 100vw;
  height: 100vh;
  border: none;
  border-radius: 0;
}

.modal-body-custom {
  position: absolute !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  padding-top: 0px !important;
  box-sizing: border-box;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  border: none !important;
  padding: 10px !important;
  background-color: white !important;
  z-index: 5;
}
.header-no-image {
  top: 40px;
}
.modal-header-custom {
  position: fixed !important;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  width: 100%;
  max-width: 450px;
  /* max-height: 350px; */
  z-index: 1;
  padding: 0px !important;
  border: none !important;
  background-color: white;
}
.back-image {
  display: flex;
  justify-content: center;
  width: 100%;
}

.modal-footer-custom {
  position: fixed !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  box-sizing: border-box !important;
  bottom: 0;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  padding: 0px !important;
  /* left: 50%; */
  /* transform: translateX(-50%); */
  border: none !important;
  background-color: white !important;
  z-index: 10;
}

.modal-header-custom .undo-button {
  position: absolute;
  top: 10px;
  left: 5px;
  padding: 5px;
}
.undo-button:active {
  background-color: #eeeeee;
  border-radius: 5px;
}
.undo-button:hover {
  background-color: #eeeeee;
  border-radius: 5px;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.arrow-left {
  filter: drop-shadow(10px 0 0 current-color) drop-shadow(-10px 0 0 current-color) !important;
}

.detail-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 50px;
  pointer-events: none;
}