.Image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}

.image-menu {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 150px;
  background: linear-gradient(rgba(0, 0, 0, 0.15), transparent);
  pointer-events: none;
}