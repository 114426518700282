.Item {
  box-sizing: border-box;
  border-bottom: 8px solid #F3F5F7;
}

.detail-item-name {
  margin-top: 3px;
  font-family: Jalnan;
  font-size: 20px;
  color: #2b2b2b;
}

.detail-item-price {
  display: flex;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 10px;
  padding: 0px 5px;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bolder;
  color: #2b2b2b;
}

.detail-item-desc {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 0px 8px;
  margin: 5px 0px;
  font-size: 16px;
  color: #7d7d7d;
  white-space: pre-wrap;
}

.detail-item-bnh {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  margin-top: 20px;
  border-radius: 5px;
  background-color: #4ec6e5;
  font-size: 12px;
  color: white;
  text-align: center;
}

.detail-item-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
}

.OptionInfo {
  margin-top: 20px;
  margin-bottom: 20px;
}

.grp-section {
  margin-bottom: 20px;
}

.grp-name-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  font-size: 18px;
  font-weight: bolder;
  padding-left: 5px;
  margin-bottom: 5px;
}

.grp-name-ess {
  background-color: #bbebe5;
  color: #07746A;
}

.grp-name-ext {
  background-color: #feefd3;
  color: orange;
}

.Essential > Form {
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 10px 5px;
  font-size: 18px;
}
.essential-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-radio input[type="radio"] {
  background-color: #05b8a0 !important;
  border-color: #05b8a0 !important;
  accent-color: #4ec6e5 !important;

}

.Extra > Form {
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 10px 5px;
  font-size: 18px;
}

.custom-checkbox input[type='checkbox'] {
  background-color: #05b8a0 !important;
  border-color: #05b8a0 !important;
  accent-color: #4ec6e5 !important;
}

.extra-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox-section {
  display: flex;
  justify-content: flex-start;
}
.counter-section {
  display: flex;
  justify-content: flex-end;
}
.price-section {
  width: 27%;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  margin-left: 3px;
}
.grp-option-section {
  margin-top: -20px;
}

.ItemCounter {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  margin-bottom: 90px;
}
.item-counter-border-section {
  width: 100%;
  height: 5px;
  background-color: #ffffff;
}
.item-counter-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 5px;
}
.item-counter-section > div {
  font-size: 18px;
  font-weight: bolder;
}