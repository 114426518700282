.fullscreen-modal {
  position: relative;
  box-sizing: border-box;
  /* border: none !important; */
}

.app-bar {
  position: relative !important;
  box-sizing: border-box !important;
  box-shadow: none !important;
  background-color: #05b8a0 !important;
  width: 100%;
  max-width: 450px;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.icon {
  color: white !important;
}
.icon:hover{
  color: #eeeeee !important;
}

.tool-bar {
  position: relative !important;
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
}
.tool-typography {
  position: absolute !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  font-size: 18px;
  font-weight: bold;
  color: white;
}
.dialog-body {
  position: relative !important;
  height: 100% !important;
}
.go-order-button {
  position: fixed !important;
  box-sizing: border-box !important;
  width: 100% !important;
  height: 100px !important;
  bottom: 20 !important;
  background-color: white !important;
}

.cart-body {
  border: none !important;
  background-color: white;
  width: 100%;
  max-width: 450px;
  margin-bottom: 100px;
  left: 50% !important;
  transform: translateX(-50%) !important;
}