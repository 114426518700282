.Title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.shopName {
  font-family: NanumR;
  font-size: 20px;
  font-weight: bold;
}
.title-section {
  display: flex;
  flex-direction: column;
}

.mobile-tableName {
  font-family: NanumB;
  font-size: 15px;
  font-weight: bolder;
  color: #07746A;
  padding: 5px 10px;
}