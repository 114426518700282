.Counter {
  display: flex !important;
  align-items: center !important;
  border: 1px solid #05b8a0 !important;
  height: 30px;
}
.Counter.small {
  width: 60px;
}
.Counter.large {
  width: 100px;
}
.Counter > button {
  width: 33%;
}
.Counter > div {
  width: 34%;
  text-align: center;
}
.counter-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100%;
  border: none !important;
  background-color:white !important;
  color: black !important;
  text-align: center;
}

.max {
  color: #eeeeee !important;
}
.min {
  color: #eeeeee !important;
}
