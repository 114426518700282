@font-face {
  font-family: Jalnan;
  src: url('/public/Jalnan2TTF.ttf');
}
@font-face {
  font-family: NanumR;
  src: url('/public/NanumSquareR.woff2');
}
@font-face {
  font-family: NanumB;
  src: url('/public/NanumSquare_acB.ttf');
}
body {
  display: flex;
  margin: 0px;
  width: 100%;
  background-color: rgb(245, 245, 245)
}

#root {
  background-color: white;
  width: 100%;
  max-width: 450px;
  height: 100%;
  min-height: 100vh;
  margin: 0px auto;
  box-shadow: rgba(100, 100, 100, 0.2) 0px 0px 29px 0px;
  padding: 0px 0px;
}

body * {
  font-family: NanumR, Arial, sans-serif, -apple-system, BlinkMacSystemFont;
}


.icons {
  display: flex;
  justify-content: flex-end;
  gap: 7px;
  margin-top: 10px;
  
}
.icon-common {
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  padding: 1px;
  cursor: pointer;  
}
.icon-common:active {
  background-color: #eeeeee;
}
.icons > img {
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  padding: 1px;
  cursor: pointer;
}
.icons > img:active {
  background-color: #eeeeee;
}

.is-top {
  /* 가로버전 */
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  top: 0px;
  width: 100%;
  max-width: 450px;
  height: 80px;
  background-color: white;
}
.is-top-v {
  position: fixed;
  z-index: 10;
  top: 0px;
  width: 70px;
  height: 100%;
  background-color: white
}
.not-top-v {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.MenuSection {
  position: relative;
}
.MenuSection-v {
  /* 세로버전 */
  position: relative;
  display: flex;
  margin-top: 10px;
}
.MenuSection-v.is-top {
  position: relative;
  /* 세로버전 */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.items-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.items {
  width: 79%;
}