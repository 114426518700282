.Mobile {
  position: relative;
  box-sizing: border-box;
}
.shop-image {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 450px;
  z-index: 1;
}
.menu-list {
  box-sizing: border-box;
  position: absolute;
  background-color: white;
  width: 100%;
  padding: 10px 16px;
  z-index: 2;
}
.menu-list.with-cart {
  padding-bottom: 100px;
}

.cart-button-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 450px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 10px;
  box-sizing: border-box;
  background-color: white;
  z-index: 100;
}