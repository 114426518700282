.table-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.custom-outline {
  background-color: transparent !important;
  border-color: #4ec6e5 !important;
  color: #4ec6e5 !important;
  height: 80px !important;
}
.custom-outline:active {
  background-color: #4ec6e5 !important;
  border-color: #4ec6e5 !important;
  color: white !important;
}