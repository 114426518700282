.confirm-button {
  background-color: #05b8a0 !important;
  border-color: #05b8a0 !important;
  width: 100%;
}
.confirm-button:active {
  background-color: #07746A !important;
}

.cancel-button {
  background-color: #b2b2b2 !important;
  border-color: #b2b2b2 !important;
  width: 100%;
}
.cancel-button:active {
  background-color: gray !important;
}
.title {
  width: 100%;
  text-align: center;
  font-family: Jalnan;
  white-space: pre-wrap;
}

.header{
  border-top: none !important;
  border-bottom: none !important;
  margin-bottom: -20px !important;
}
.footer{
  border-top: none !important;
  border-bottom: none !important;
}

.custom-modal .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 10rem);
  margin: auto;
  width: 80%;
}

.body {
  white-space: pre-wrap;
}

.custom-modal-backdrop {
  z-index: 1050; /* Set it higher than fullscreen modal */
  opacity: 0.5;  /* Adjust the opacity as needed */
  background-color: rgba(0, 0, 0, 0.5);
}

.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* 반투명 레이어 */
  z-index: 1055; /* fullscreen 모달과 CustomDialog 사이에 위치하도록 설정 */
}