.username {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
  height: 40px;
  margin-bottom: 5px;
}
.password {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
}

.err-msg {
  color: red;
  font-size: 14px;
}