.scrollable-tabs-container-t {
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* 세로 스크롤 설정 */
  align-items: center;
  padding: 10px 0px;
  margin: 0px 0px;
  margin-right: 0px;
  width: 70px;
  /* border-right: 1px solid #ccc; */
  /* background-color: orange; */
}

.scrollable-tabs-t {
  display: flex;
  flex-direction: column; /* flex 방향을 세로로 변경 */
  flex-wrap: nowrap;
  gap: 5px;
  width: 100%;
}

.tab-button-t {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 5px 3px;
  border: 1px solid #07746A;
  box-shadow: rgba(100, 100, 100, 0.2) 0px 0px 15px 0px;
  border-radius: 5px;
  width: 100%; /* 가로 폭을 100%로 */
  min-height: 60px;
  background-color: white;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  color: black;
  white-space: pre-wrap;
}

.tab-button-t:hover, .tab-button-t:focus {
  background-color: #05b8a0;
  color: white;
}
.tab-button-t:active {
  background-color: #05b8a0;
  color: white;
}
.scroll-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 24px;
  cursor: pointer;
  outline: none;
  z-index: 1;
  background-color: white;
}

.scroll-arrow.up {
  position: absolute;
  padding-top: 10px;
  padding-bottom: 0px;
  top: 0;
  color: #b2b2b2;
}

.scroll-arrow.down {
  position: absolute;
  padding-top: 0px;
  padding-bottom: 10px;
  bottom: 0;
}